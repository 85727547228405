import React from "react";

function WhatWeDo() {
  return (
    <>
      <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x text-dark">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            />
          </svg>{" "}
        </div>
      </div>

      <section class="bg-gradient-dark-black py-8 py-md-11">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-7 text-center">
              <h2 class="display-3 fw-bold text-white">What we do</h2>

              <p class="lead text-gray-500 mb-9">
                We make it easy. Whether it’s one of our existing telecoms
                solutions, or a bespoke development, get in touch with us today.
              </p>
            </div>
          </div>
          <div class="row mt-n7">
            <div class="col-12 col-sm-6 col-lg-4 mt-7">
              <div class="card h-100 bg-dark rounded-3">
                <div class="card-body p-5 pb-0 my-auto">
                  <img
                    class="img-fluid"
                    src="assets/img/illustrations/0800icon.png"
                    alt="..."
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div class="card-body p-5">
                  <h3 class="fw-bold text-white">Numbers</h3>

                  <p class="text-gray-500 mb-0">
                    We have numbers from every UK range, which includes Premium,
                    non-geographic and all local dialling codes.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 mt-7">
              <div class="card h-100 bg-dark rounded-3">
                <div class="card-body p-5 pb-0 my-auto">
                  <img
                    class="img-fluid"
                    src="assets/img/illustrations/servericon.png"
                    alt="..."
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div class="card-body p-5">
                  <h3 class="fw-bold text-white">Hosted PBX</h3>

                  <p class="text-gray-500 mb-0">
                    Use our cloud-based PBX to create scalable telephony
                    solutions quickly and easily.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 mt-7">
              <div class="card h-100 bg-dark rounded-3">
                <div class="card-body p-5 pb-0 my-auto">
                  <img
                    class="img-fluid"
                    src="assets/img/illustrations/networkicon.png"
                    alt="..."
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div class="card-body p-5">
                  <h3 class="fw-bold text-white">SIP Trunk</h3>

                  <p class="text-gray-500 mb-0">
                    Retain your existing phone system but get the benefits of a
                    modern VoIP solution.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 mt-7">
              <div class="card h-100 bg-dark rounded-3">
                <div class="card-body p-5 pb-0">
                  <img
                    class="img-fluid"
                    src="assets/img/illustrations/miconnect.png"
                    alt="..."
                    style={{ maxWidth: "150px" }}
                  />
                </div>

                <div class="card-body p-5">
                  <h3 class="fw-bold text-white"></h3>

                  <p class="text-gray-500 mb-0">
                    Callback and omni channel solution for customers to contact
                    you using their preferred method.
                  </p>
                </div>
                <div class="card-body p-5 ">
                  <a
                    className="btn btn-info shadow"
                    href="https://www.mi-connect.co.uk"
                  >
                    Visit Mi-Connect <i className="fe fe-arrow-right ms-3"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 mt-7">
              <div class="card h-100 bg-dark rounded-3">
                <div class="card-body p-5 pb-0 my-auto">
                  <img
                    class="img-fluid"
                    src="assets/img/illustrations/talkiniologo.png"
                    alt="..."
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div class="card-body p-5">
                  <h3 class="fw-bold text-white"></h3>

                  <p class="text-gray-500 mb-0">
                    Talkin connects a local or national landline number to your
                    mobile, so you can answer business calls wherever you are.
                  </p>
                </div>

                <div class="card-body p-5 pt-0">
                  <a
                    className="btn btn-info shadow"
                    href="https://www.talkin.io"
                  >
                    Visit Talkin <i className="fe fe-arrow-right ms-3"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhatWeDo;
