import React from 'react'

function CTA(){
    return(
<section class="py-10 py-md-14 bg-black">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">

            
            <h6 class="text-uppercase text-info mb-6">
                BUILD BESPOKE TELECOMS SOLUTIONS
            </h6>

            
            <h1 class="display-1 fw-bold text-white">
            Powerful, scalable technology 
            </h1>

            
            <p class="fs-lg text-gray-500 mb-6 mb-md-8">
            Integrate directly with our API’s to build products and solutions that your business requires, whilst benefitting from our carrier-grade technology.
            </p>

          </div>
        </div> 
      </div> 
    </section>
    )
}
export default CTA