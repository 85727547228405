import React from "react";

function Footer() {
  return (
    <>
      <div class="bg-black">
        <div class="container border-top border-gray-900-50"></div>
      </div>

      <footer class="py-8 py-md-11 bg-black">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-4">
              <img
                src="./assets/img/illustrations/mitellogo.png"
                alt="..."
                class="footer-brand img-fluid mb-2"
                style={{ maxWidth: "150px" }}
              />

              <p class="text-gray-700 mb-2">
                Suite 2. Baker House, Rodway Road, Bromley, BR1 3JL
              </p>

              <a
                href="/privacy-policy"
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy Policy
              </a>
            </div>

            <div class="col-6 col-md-4 col-lg-4 d-none d-md-block">
              <h6 class="fw-bold text-uppercase text-gray-700">
                Our Solutions
              </h6>

              <ul class="list-unstyled text-gray-500 mb-6 mb-md-8 mb-lg-0">
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Inbound
                  </a>
                </li>
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Hosted
                  </a>
                </li>
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    SIP
                  </a>
                </li>
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Connect
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
              <h6 class="fw-bold text-uppercase text-gray-700">Contact Us</h6>

              <ul class="list-unstyled text-gray-500 mb-6 mb-md-8 mb-lg-0">
                <li class="mb-3">
                  <a
                    href="mailto:hello@mi-telecom.co.uk"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    hello@mi-telecom.co.uk
                  </a>
                </li>
                <li class="mb-3">
                  <a
                    href="tel:+443308803000"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    0330 880 3000
                  </a>
                </li>

                <li class="mb-3">
                  <a
                    className="navbar-btn btn btn-sm btn-info lift ms-auto d-none d-md-block"
                    href="https://mi-dashboard.co.uk"
                    target="_blank"
                  >
                    Mi-Dashboard
                  </a>
                  <p
                    className="mb-7 mb-md-9 d-block d-md-none"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <a
                      className="btn btn-info shadow lift"
                      href="javascript:Mi_connect_API.maximise()"
                    >
                      Let's Chat <i className="fe fe-arrow-right ms-3"></i>
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
