import React from 'react'

function About(){
    return(
        
        <section class="pt-8 pt-md-11 bg-black">
          <div class="container">
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-6 col-lg-5 order-md-1">
    
              
                <h2 class="display-4 fw-bold text-white mb-6 mb-md-8">
                Instantly create and manage services.
                </h2>
    
                
                <p class="text-gray-500 lead mb-6">
                  Take control of your communications and manage your services on our cloud PBX platform.
                </p>
    
                
                
    
              </div>
              <div class="col-12 col-md-6 col-lg-6 order-md-0">
    
                
                <div class="position-relative">
    
                  
                  <div class="position-relative vw-md-50 p-7 p-md-9 ps-0 overflow-hidden float-end" style={{maxWidth: '800px'}}>
    
                    
                    <div class="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor rounded-4"></div>
    
                    
                    <img class="position-relative w-100 w-md-120 float-end" src="assets/img/screenshots/desktop/Mi-telecom-PBX.png" alt="..."/>
    
                  </div>
    
                  
                  
    
                </div>
    
              </div>
            </div> 
          </div>
        </section>
    )
}
export default About