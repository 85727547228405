import React from "react";

import Welcome from "../Components/Welcome";
import WhatWeDo from "../Components/WhatWeDo";
import About from "../Components/About";
import AboutReversed from "../Components/AboutReversed";
import CTA from "../Components/CTA";
import Patners from "../Components/Patners";
function Home() {
  return (
    <>
      <div className="row">
        <Welcome />
      </div>
      <div className="row">
        <WhatWeDo />
      </div>
      <div className="row">
        <AboutReversed />
      </div>
      <div className="row">
        <About />
      </div>
      <div className="row">
        <CTA />
      </div>
      <div className="row">
        <Patners />
      </div>
    </>
  );
}
export default Home;
