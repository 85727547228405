import React from 'react'

function Patners(){
    return(
        <div className="row align-items-center bg-black justify-content-center patners-div">
        <div className="col-12">

          
          <h6 className="text-uppercase text-center text-info mb-6">
          OUR PARTNERS
          </h6>

        </div>

        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
          <img class="img-fluid" src="assets/img/illustrations/6dg-white.png" alt="..." />
          </div>
        </div>


        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0 ">
          <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
          <img class="img-fluid" src="assets/img/illustrations/asterisk-white.png" alt="..."/>
          </div>
        </div>


        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
          <img class="img-fluid" src="assets/img/illustrations/bt-white.png" alt="..."/>
          </div>
        </div>


        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
          <img class="img-fluid" src="assets/img/illustrations/counterpath-white.png" alt="..."/>
          </div>
        </div>


        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
          <img class="img-fluid" src="assets/img/illustrations/Gamma-white.png" alt="..." />
          </div>
      </div>


      <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">    
<div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
<img class="img-fluid" src="assets/img/illustrations/viatel-white.png" alt="..." />
</div>
</div>

</div>
    )
}
export default Patners