import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

function PrivacyPolicy() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function previousPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div>
      <Document file="/privacy.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={width} />
      </Document>
      <p>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-auto">
            <a
              className="navbar-btn btn btn-sm btn-info lift ms-auto d-none d-md-block"
              onClick={() => previousPage()}
            >
              Previous Page
            </a>
          </div>
          <div className="col-auto">
            <a
              className="navbar-btn btn btn-sm btn-info lift ms-auto d-none d-md-block"
              onClick={() => nextPage()}
            >
              Next Page
            </a>
          </div>
        </div>
      </p>
    </div>
  );
}
export default PrivacyPolicy;
