import React from "react";
import AOS from "aos";
import "./App.css";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Menu from "./Components/Menu";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import { Route, Routes } from "react-router-dom";

function App() {
  AOS.init();

  return (
    <div className="container-fluid main-container">
      <div className="row">
        <Menu />
      </div>
      <main>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>

      <div className="row">
        <Footer />
      </div>
    </div>
  );
}

export default App;
