import React, { useEffect, useState } from "react";

function Menu() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [inverted, setInverted] = useState(false);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    if (window.location.pathname === "/privacy-policy") {
      setInverted(true);
    }
  });

  return (
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <img
          src="./assets/img/illustrations/mitellogo.png"
          className={"navbar-brand-img " + (inverted ? "inverted" : "")}
          alt="..."
        />

        <button
          class="navbar-toggler"
          type="button"
          onClick={() => handleNavCollapse()}
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarCollapse"
        >
          <button
            class="navbar-toggler"
            onClick={() => handleNavCollapse()}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fe fe-x"></i>
          </button>
          <ul class="navbar-nav ms-auto">
            {isNavCollapsed == false && (
              <li class="nav-item dropdown d-block d-md-none">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarPages"
                  data-bs-toggle="dropdown"
                  href="#"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Mi-Telecom
                </a>
                <div
                  class="dropdown-menu dropdown-menu-lg"
                  aria-labelledby="navbarPages"
                >
                  <div class="row gx-0">
                    <div class="col-6">
                      <div class="row gx-0">
                        <div class="col-12 col-lg-6">
                          <h6 class="dropdown-header">Our Platforms</h6>

                          <a
                            class="dropdown-item"
                            href="https://mi-dashboard.co.uk"
                            target="_blank"
                          >
                            Mi-Dashboard
                          </a>
                          <a
                            class="dropdown-item mb-5"
                            href="https://pbx.mi-telecom.co.uk"
                            target="_blank"
                          >
                            Mi-PBX
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row gx-0"></div>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>

          <div style={{ display: "flex" }}>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fe fe-x"></i>
              </button>
              <a
                className={
                  "navbar-btn btn-primary btn-sm btn-info-2 btn-info  lift ms-auto  " +
                  (inverted ? "inverted" : "")
                }
                href="https://pbx.mi-telecom.co.uk"
                target="_blank"
              >
                Mi-PBX
              </a>
            </div>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fe fe-x"></i>
              </button>
              <a
                className="navbar-btn btn btn-sm btn-info lift ms-auto"
                href="https://mi-dashboard.co.uk"
                target="_blank"
              >
                Mi-Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Menu;
