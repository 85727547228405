import React from "react";

function AboutReversed() {
  return (
    <section class="pt-8 pt-md-11 bg-black">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6 col-lg-5">
            <h2 class="display-3 fw-bold text-white mb-6 mb-md-8">
              Live call data at your fingertips.
            </h2>

            <p class="text-gray-500 lead mb-6">
              Use our responsive dashboard to view and manage performance in
              real time.
            </p>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="position-relative vw-md-50 pt-7 ps-7 ps-md-9 overflow-hidden"
              style={{ maxWidth: "800px" }}
            >
              <div class="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor rounded-4"></div>

              <img
                class="position-relative w-100 w-md-150"
                src="assets/img/screenshots/desktop/Mi-telecom-Agents-image.png"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutReversed;
