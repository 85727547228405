import React from "react";

function Welcome() {
  return (
    <>
      <section className="mt-n11 pt-12 pb-8 pt-md-14 pb-md-11 bg-black bg-pattern-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 text-center">
              <h1
                className="display-1 fw-bold text-white"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Telecoms for any business.
              </h1>

              <p
                className="lead text-gray-500 mb-6 mx-auto"
                data-aos="fade-up"
                data-aos-delay="100"
                style={{ maxWidth: "500px" }}
              >
                We connect millions of telephone calls for businesses each year,
                with our modern cloud technology and easy to use platforms.
              </p>

              <p
                className="mb-7 mb-md-9"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <a
                  className="btn btn-info shadow lift"
                  href="javascript:Mi_connect_API.maximise()"
                >
                  Let's Chat <i className="fe fe-arrow-right ms-3"></i>
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="200">
              <div className="position-relative pt-7 pb-7 ps-7 mb-7 mb-md-9 rounded-4 shadow-multicolor overflow-hidden">
                <div className="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor"></div>

                <img
                  className="position-relative img-fluid rounded-start shadow-lg"
                  src="assets/img/screenshots/desktop/Mi-telecom-header-image.png"
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <h6 className="text-uppercase text-center text-info mb-6">
                POWERING TELEPHONY ACROSS ALL SECTORS
              </h6>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/digital-nrg-white.png"
                  alt="..."
                />
              </div>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0 ">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/fonix-white.png"
                  alt="..."
                />
              </div>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/magic-breaks-white.png"
                  alt="..."
                />
              </div>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/reach-white.png"
                  alt="..."
                />
              </div>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/travel-village-white.png"
                  alt="..."
                />
              </div>
            </div>

            <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
              <div className="img-fluid text-white mb-2 mb-md-0 d-flex justify-content-center">
                <img
                  class="img-fluid"
                  src="assets/img/illustrations/vertical-white.png"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Welcome;
